<template>
  <div class="neutral" style="width: 100%; height: 100%; text-align: center">
    <v-progress-circular
      indeterminate
      size="45"
      color="primary"
      class="mt-8"
      v-if="loading"
    ></v-progress-circular>

    <div v-show="showError" class="ui negative message">
      <div class="header">{{ errorMsg }}</div>
    </div>

    <iframe v-show="!showError" ref="viewer" type="application/pdf"></iframe>
  </div>
</template>
<script>
import Api from '@/resources/NajaSocialApi'
export default {
  data() {
    return {
      pdf: null,
      loading: false,
      showError: false,
      errorMsg: 'Error ao carregar laudo',
    }
  },

  created() {
    this.fetchData()
  },

  methods: {
    fetchData() {
      this.loading = true
      this.showError = false

      Api.medicalReportPDF(this.$route.params.id)
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })

          // $vuetify.breakpoint.mobile
          if (this.$isMobile()) {
            const link = document.createElement('a')
            const URLAgent = window.URL || window.webkitURL

            link.href = URLAgent.createObjectURL(blob)
            link.download = `Laudo_${this.$route.params.id}.pdf`
            document.body.append(link)
            // link.remove()
            link.click()

            setTimeout(() => URL.revokeObjectURL(link.href), 7000)
            // const URLAgent = window.URL || window.webkitURL;
            // const url = URLAgent.createObjectURL(blob)
            // window.open(url)
          } else {
            this.$refs.viewer.setAttribute(
              'src',
              window.URL.createObjectURL(blob)
            )
          }
        })
        .catch(error => {
          if (error.response.status == 404) {
            this.errorMsg = 'Laudo não encontrado'
          }
          this.showError = true
          throw error
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="css" scoped>
iframe {
  overflow: auto;
  width: 100%;
  height: 100%;
  border: none;
}

.ui.negative.message {
  max-width: 300px;
  margin: 0 auto;
  margin-top: 100px;
  text-align: center;
}
</style>
